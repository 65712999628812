import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { QueryClient } from '@tanstack/react-query';
import Head from 'next/head';

// utils
import renderBlock from '../src/utils/blocks';
import getServerSideTranslations from '../src/utils/server-side';

// Services
import { QUERY_KEY_CONTENT_TRANSLATION, getContentTranslationProxy } from '../src/services/content-translations';

// Hooks
import useSeoLinks from '../src/hooks/useSeoLinks';

// Components
import PageTransition from '../components/templates/PageTransition/PageTransition';
import PageLayout from '../components/templates/PageLayout/PageLayout';

function Index({ page }) {
  const { canonicalUrl } = useSeoLinks();

  const data = useMemo(() => page?.data || {}, [page?.data]);

  return (
    <>
      <Head>
        <title>{data?.metaTitle}</title>
        <meta name="description" content={data?.metaDescription} />

        <link rel="canonical" href={canonicalUrl} />
      </Head>

      <PageLayout
        header={data?.header?.data}
        footer={data?.footer?.data}
      >
        <PageTransition>
          {data?.blocks?.length ? data?.blocks.map((block, index) => (
            <Fragment key={`${block.type}-${index}`}>
              {renderBlock(block)}
            </Fragment>
          )) : null}
        </PageTransition>
      </PageLayout>
    </>
  );
}

Index.propTypes = {
  page: PropTypes.shape({
    data: PropTypes.shape({
      blocks: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        data: PropTypes.shape({}),
      })),
    }),
  }).isRequired,
};

export async function getServerSideProps({ locale, query }) {
  const translations = await getServerSideTranslations(locale);
  const preview = { preview: query.previewMode ? true : null, contentId: query.contentId || null };

  const queryClient = new QueryClient();
  let page = {};
  try {
    page = await queryClient.fetchQuery(
      [QUERY_KEY_CONTENT_TRANSLATION, { contentTypeId: 'home', locale }],
      () => getContentTranslationProxy({ contentTypeId: 'home', pageSize: 1, ...preview }, locale, query.previewMode)
        .then(async (res) => {
          if (res?.data?.length) {
            return res?.data?.[0];
          }
          throw new Error('notFound');
        }),
    );
  } catch (error) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      page,
      ...translations,
    },
  };
}

export default Index;
